module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'DopeAI - AI for Pro Photographers', // Navigation and Site Title
  titleAlt: 'DopeAI', // Title for JSONLD
  description: 'We are transforming workflows of professional photographers into more performant, efficient, and automated ones using AI',
  headline: 'Empowering Photographers With AI', // Headline for schema.org JSONLD
  url: 'https://dopeai.tech', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/icons/icon-48x48.png', // Used for SEO
  sharingCards: {
    'square600': '/images/sharing-cards/sharing-card-600x600.png',
    'wide600': '/images/sharing-cards/sharing-card-1200x600.png',
    'wide630': '/images/sharing-cards/sharing-card-1200x630.png'
  },
  ogSiteName: 'DopeAI', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/icon.png', // Used for manifest favicon generation
  orgName: 'DopeAI',
  shortName: 'DopeAI', // shortname for manifest. MUST be shorter than 12 characters
  author: 'DopeAI', // Author for schemaORGJSONLD
  themeColor: '#FFFFFF',
  backgroundColor: '#FFFFFF',

  twitter: '@dopeaitech'
}
