import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarFilled, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";

export function StarIcon({ filled, className }) {
  return (
    <FontAwesomeIcon className={`${className || ''}`} icon={filled ? faStarFilled : faStar}></FontAwesomeIcon>
  );
}

export function StarRating({ rating, className }) {
  return (
    [...Array(5).keys()].map(i => <StarIcon key={i} className={className} filled={i < rating} />)
  );
}

export function StarRatingFourAndHalf({ rating, className }) {
  return (
    <>
    {[...Array(4).keys()].map(i => <StarIcon key={i} className={className} filled={true} />)}
    <FontAwesomeIcon className={`${className || ''}`} icon={faStarHalfAlt}></FontAwesomeIcon>
    </>
  );
}