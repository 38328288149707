import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import website from '../../../config/website'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, type, article, series, node }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogSiteName,
      ogLanguage,
      twitter
    },
  } = site

  const prefixedBanner = []
  Object.keys(defaultBanner).forEach(key => {
    prefixedBanner[key] = `${siteUrl}${defaultBanner[key]}`
  })

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: banner || prefixedBanner,
    url: `${siteUrl}${pathname || ''}`,
  }

  const orgLogo = {
    "@type": "ImageObject",
    "url": `${siteUrl}${website.logo}`,
    "width": 48,
    "height": 48
  }

  const organization = {
    "@type": "Organization",
    name: website.orgName,
    url: siteUrl,
    logo: orgLogo
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: seo.url,
    headline: title || headline,
    description: seo.description,
    name: seo.title,
    author: organization,
    publisher: organization,
    datePublished: '2020-11-18',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: seo.image['wide630'],
    },
    inLanguage: siteLanguage,
    copyrightHolder: organization,
    copyrightYear: '2020',
    mainEntityOfPage: siteUrl
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let schemaArticle = null

  if (type === 'Article' && article) {
    const author = article.author
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      publisher: organization,
      author: {
        "@type": "Person",
        name: author.name,
        image: {
          "@type": "ImageObject",
          url: `${siteUrl}${author.image}`,
          width: 240,
          height: 240
        },
        url: `${siteUrl}${author.url}`,
        sameAs: author.socialLinks
      },
      headline: seo.title,
      url: seo.url,
      datePublished: article.datePublished,
      // dateModified: node.last_publication_date,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image['wide630'],
      },
      keywords: article.tags && article.tags.join(','),
      description: seo.description,
      inLanguage: siteLanguage,
      copyrightHolder: organization,
      copyrightYear: '2020',
      mainEntityOfPage: siteUrl
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  let schemaSeries = null

  if (type === 'Series' && series) {
    schemaSeries = {
      "@context": "https://schema.org",
      "@type": "Series",
      url: seo.url,
      name: series.name,
      mainEntityOfPage: siteUrl
    }

    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image['wide630']} />
        <link rel="canonical" href={seo.url} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {(!type || type === 'Webpage') && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {type === 'Article' && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        {type === 'Series' && <script type="application/ld+json">{JSON.stringify(schemaSeries)}</script>}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image['wide630']}
        squareImage={seo.image['square600']}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={ogSiteName}
      />
      <Twitter
        title={seo.title}
        image={seo.image['wide600']}
        desc={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.object,
  pathname: PropTypes.string,
  article: PropTypes.object,
  node: PropTypes.object,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: null,
  node: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner {
          square600
          wide600
          wide630
        }
        headline
        siteLanguage
        ogSiteName
        ogLanguage
        twitter
      }
    }
  }
`
