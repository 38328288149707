import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react"
import { StarRating } from "./starrating";

export function Hero({ children, className, nogrid }) {
  return (
    <section className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 ${className || ''}`}>
      <div className={`${nogrid ? '' : 'grid md:grid-cols-12 gap-10 md:gap-6'} max-w-5xl mx-auto`}>
        {children}
      </div>
    </section>
  );
}

export function Section({ children, className, gridClassName, alternate, nogrid, noborder, nopadding, ...rest }) {
  alternate = false
  return (
    <section {...rest} className={`mx-auto max-w-6xl xl:max-w-7xl ${alternate && 'bg-blue-700'} ${alternate ? 'text-white' : 'text-gray-500'} px-4 sm:px-6 lg:px-8 ${className || ''}`}>
      <div className={`${gridClassName || ''} ${nopadding ? '' : 'py-24'} ${noborder ? '' : 'border-t-2 border-gray-200'} ${nogrid ? '' : 'grid md:grid-cols-12 gap-10 md:gap-6'}`}>
        {children}
      </div>
    </section>
  );
}

export function SectionText({ children, className }) {
  return (
    <div className={`max-w-lg mx-auto md:col-span-5 md:order-2 my-auto text-center md:text-left ${className || ''}`}>
      {children}
    </div>
  );
}

export function SectionImage({ children, className }) {
  return (
    <div className={`md:col-span-7 md:order-1 my-auto ${className || ''}`}>
      {children}
    </div>
  );
}

export function SectionTextCaption({ children, className }) {
  return (
    <p className={`mb-6 text-lg font-extrabold text-gray-500 ${className || ''}`}>
      {children}
    </p>
  );
}

export function SectionTextHeading({ children, className }) {
  return (
    <h2 className={`text-3xl lg:text-4xl xl:text-4xl font-extrabold text-gray-900 ${className || ''}`}>
      {children}
    </h2>
  );
}

export function SectionTextSubtitle({ children, className }) {
  return (
    <p className={`mt-3 sm:mt-5 text-lg lg:text-xl xl:text-xl ${className || ''}`}>
      {children}
    </p>
  );
}

export function ImageFeatureCard({ image, title, children }) {
  return (
    <div className="md:col-span-4 text-center">
      <div className="mx-auto max-w-sm rounded-lg overflow-hidden shadow-lg">
        <img className="w-full" src={image} alt={title} title={title}/>
        <div className="px-6 py-4">
          <h3 className="text-lg lg:text-xl font-bold text-gray-700 mb-2">{title}</h3>
          <p className="lg:text-lg">
            {children}
          </p>
        </div>
      </div>
    </div>
  );
}

export function TestimonialCard({ author, profilePic, title, instagram, location, children }) {
  return (
    <div className="md:col-span-4 text-center">
      <blockquote className="mx-auto p-3 max-w-sm rounded-lg overflow-hidden shadow-lg">
        <a className="post-author-image-link h-48 w-48" href={`https://www.instagram.com/${instagram}/`} target="_blank">
          <img className="inline-block h-48 w-48 rounded-full ring-2 ring-white" 
            src={profilePic} width={128} height={128} 
            alt=" profile picture" title=" profile picture linked to review of OptiCull">
          </img>
        </a>
        <div className="px-6 py-4">
          <p className="mt-3"><b>{author}</b> . <a href={`https://www.instagram.com/${instagram}/`} target="_blank">@{instagram}</a> . {location}</p>
          <div className="mt-3 flex items-center justify-center">
            <StarRating rating={5} className="text-xl text-yellow-500" />
          </div>
          <h3 className="mt-4 text-lg lg:text-xl font-bold text-gray-700 mb-2">{title}</h3>
          <p className="">
            "{children}"
          </p>
        </div>
      </blockquote>
    </div>
  );
}

export function LearnMore({ to, label, className }) {
  return (
    <p className="mt-4">
      <Link to={to} className={`text-blue-500 hover:text-blue-600 ${className || ''}`}>
        <span>{label || 'Learn More'}</span>
        <FontAwesomeIcon className="ml-2" icon={faArrowRight} size="xs"></FontAwesomeIcon>
      </Link>
    </p>
  );
}