import * as React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FooterColumnHeader } from "./footer";
import { useState } from "react";

const subscribeToNewsletter = (email) => {

  var headers = new Headers()
  headers.append('api-key', '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')
  headers.append('Content-Type', 'application/json')
  headers.append('accept', 'application/json')

  const request = new Request('https://api.sendinblue.com/v3/contacts', {
    method: 'POST',
    headers,
    body: `{"email": "${email}", "listIds":[3]}`
  });

  return fetch(request)
}

export function NewsletterSubsForm() {
  const [success, setSuccess] = useState(null)
  const [message, setMessage] = useState(null)

  return (
    <div className="md:max-w-sm flex flex-col py-8 border-t-2 border-gray-700 md:border-t-0">
      <FooterColumnHeader>Subscribe to our newsletter</FooterColumnHeader>
      <div className="my-2 text-gray-300">The latest updates, articles, and resources, sent to your inbox.</div>

      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setTimeout(() => { setSubmitting(false) }, 2000)
          const response = await subscribeToNewsletter(values.email)
          setSuccess(response.ok ? "true" : "false")
          if (!response.ok) {
            const error = await response.json()
            setMessage((error && error.message) || 'Unknown')
          }
        }}
      >
        {({ isSubmitting }) => (
          <>
            <Form className="flex space-x-4 items-start">
              <div className="flex-grow flex flex-col space-y-1">
                <Field type="email" name="email" className="h-11 w-full px-4 focus:ring-blue-500 focus:border-blue-500 block border-gray-300 rounded-md text-gray-900" placeholder="your@email.com" />
                <ErrorMessage name="email" component="div" className="text-xs text-red-300" />
              </div>
              <button className="btn btn-blue" type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
            {success === "true" && <div className="text-sm text-green-300 mt-2">You have been added to the newsletter.</div>}
            {success === "false" && <div className="text-sm text-red-300 mt-2 truncate">There was a problem: {message}.</div>}
          </>
        )}
      </Formik>

    </div>
  )
}