import * as React from "react"
import { useState } from "react"
import { Transition } from '@headlessui/react'

import icon from "../images/icon.svg"
import cullAiIcon from "../images/cullai-icon.svg"
import opticullIcon from "../images/opticull-icon.svg"

import { Dropdown, DropdownMenu, DropdownMenuItem } from "./dropdown"

import { MobileMenu, MobileMenuItem, MobileMenuGroupHeading, MobileMenuFooter, MobileMenuFooterItem, MobileMenuHeader, MobileMenuItems } from "./mobilemenu"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCamera, faLaptop } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"

function Header(props) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logo = (
    <Link className="flex" to="/">
      <img className="-mt-1 h-8 w-auto sm:h-10" src={icon} alt="DopeAI Icon" title="DopeAI Icon with rising mountains"/>
      <div className="font-bold self-center text-xl sm:text-2xl text-gray-800 hover:text-gray-900 pr-1">DopeAI</div>
    </Link>
  );

  return (
    <header className="relative bg-white border-b-2 border-gray-100">

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div className="flex justify-between items-center py-5">

          {/* <div className="flex justify-start lg:w-0 lg:flex-1"> */}
          {logo}
          {/* </div> */}

          <div className="-mr-2 -my-2 md:hidden">
            <button type="button"
              onClick={() => setIsMobileMenuOpen(true)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500" aria-expanded="false">
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon icon={faBars} fixedWidth />
            </button>
          </div>

          <nav className="hidden md:flex space-x-10">

            <Dropdown name="Products">

              <DropdownMenu>
                <DropdownMenuItem icon={opticullIcon} heading="OptiCull"
                  subheading="Fastest yet simple photo culling app. Best for personalized automated culling or manual culling with lightning fast RAW and face previews."
                  link="/opticull"
                  trailingIcons={[faCamera, faLaptop]} >
                </DropdownMenuItem>
                <DropdownMenuItem icon={cullAiIcon} heading="CullAi (Legacy)"
                  subheading="Simple photo culling app. Filters out low quality photos, Groups similar photos and selects best ones from each group."
                  link="/cullai"
                  trailingIcons={[faCamera, faLaptop]} >
                </DropdownMenuItem>
              </DropdownMenu>

              {/* <DropdownFooter>
                <DropdownFooterItem icon={faPlayCircle} name="Watch Demo" />
                <DropdownFooterItem icon={faEnvelope} name="Contact Sales" />
              </DropdownFooter> */}

            </Dropdown>

            {/* <Dropdown name="Solutions">

              <DropdownMenu>
                <DropdownMenuItem icon={proPhotographers} heading="Professional Photographers" trailingIcons={[faCamera]} subheading="Products for Professional Photographers to help in photography workflows."></DropdownMenuItem>
              </DropdownMenu>

              <DropdownFooter>
                <DropdownFooterItem icon={faPlayCircle} name="Watch Demo" />
                <DropdownFooterItem icon={faEnvelope} name="Contact Sales" />
              </DropdownFooter>

            </Dropdown> */}

            <Link to="/about" className="font-bold text-lg text-gray-500 hover:text-gray-900 px-1">About</Link>
            <Link to="/blog" className="font-bold text-lg text-gray-500 hover:text-gray-900 px-1">Blog</Link>
            <Link to="/help/cullai" className="font-bold text-lg text-gray-500 hover:text-gray-900 px-1">Help</Link>

          </nav>

          <div className="hidden md:block"></div>

        </div>
      </div>

      <Transition
        show={isMobileMenuOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <MobileMenu>

            <MobileMenuHeader logo={logo} onClose={() => setIsMobileMenuOpen(false)} />

            <MobileMenuItems>

              <MobileMenuGroupHeading name="Products" />
              <MobileMenuItem icon={opticullIcon} name="OptiCull" to="/opticull" />
              <MobileMenuItem icon={cullAiIcon} name="CullAi (Legacy)" to="/cullai" />
              {/* <MobileMenuGroupHeading name="Solutions" />
              <MobileMenuItem icon={proPhotographers} name="Professional Photographers" /> */}

            </MobileMenuItems>

            <MobileMenuFooter>

              <MobileMenuFooterItem name="About DopeAI" to="/about" />
              <MobileMenuFooterItem name="Blog" to="/blog" />
              <MobileMenuFooterItem name="Help" to="/help/cullai" />
              <MobileMenuFooterItem name="User Guide" to="/help/cullai/user-guide" />

            </MobileMenuFooter>

          </MobileMenu>
        )}
      </Transition>
    </header >
  )
}

export default Header