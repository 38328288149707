import React from "react"

import Header from './header'
import { Footer } from "./footer"

export default function Layout({ children }) {
  return (

    <div className="">

      <Header></Header>
      <main className="min-h-screen">
        {children}
      </main>
      <Footer></Footer>
    </div>

  )
}