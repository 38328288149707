import * as React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"


export function MobileMenu({ children }) {
  return (
    <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        {children}
      </div>
    </div>
  )
}

export function MobileMenuHeader({ logo, onClose }) {
  return (
    <div className="pt-4 pb-6 px-2 sm:px-4">
      <div className="flex items-center justify-between">
        {logo}
        <div className="-mr-2">
          <button type="button"
            onClick={onClose}
            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
            <span className="sr-only">Close menu</span>
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </button>
        </div>
      </div>
    </div>
  )
}

export function MobileMenuItems({ children }) {
  return (
    <div className="py-6 px-5">
      <nav className="grid gap-y-4">
        {children}
      </nav>
    </div>
  )
}

export function MobileMenuItem({ icon, name, to }) {
  return (

    <Link to={to} className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
      {icon && <img className="h-8 w-8" src={icon} />}
      <span className="ml-3 text-lg font-medium text-gray-900">
        {name}
      </span>
    </Link>
  )
}

export function MobileMenuGroupHeading({ name }) {
  return (

    <div>
      <span className="text-lg font-bold text-gray-600">
        {name}
      </span>
    </div>
  )
}

export function MobileMenuFooter({ children }) {
  return (
    <div className="py-6 px-5">
      <nav className="grid grid-cols-2 gap-y-4 gap-x-8">
        {children}
      </nav>
    </div >
  )
}

export function MobileMenuFooterItem({ name, to }) {
  return (
    <Link to={to} className="text-base font-medium text-gray-900 hover:text-gray-700">
      {name}
    </Link>
  )
}