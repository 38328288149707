import * as React from "react"
import { useState } from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Transition } from '@headlessui/react'
import onClickOutside from "react-onclickoutside";
import { Link } from "gatsby"

function DropdownInternal({ name, children }) {
  const [isOpen, setIsOpen] = useState(false)
  DropdownInternal['handleClickOutside_' + name] = () => setIsOpen(false);

  return (
    <div className="relative">
      <button type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="text-gray-500 group bg-white rounded-md inline-flex items-center text-lg font-bold hover:text-gray-900 px-1"
        aria-expanded={isOpen}>
        <span>{name}</span>
        <FontAwesomeIcon icon={faChevronDown}
          className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
          aria-hidden="true" />
      </button>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95 z-20"
        enterTo="opacity-100 scale-100 z-20"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100 z-20"
        leaveTo="opacity-0 scale-95 z-20"
      >
        {(ref) => (
          <div className="absolute z-20 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              {children}
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => DropdownInternal['handleClickOutside_' + props.name]
};

export const Dropdown = onClickOutside(DropdownInternal, clickOutsideConfig);

export function DropdownMenu({ children }) {
  return (
    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
      {children}
    </div>
  )
}

export function DropdownMenuItem({ icon, heading, subheading, link, trailingIcons }) {
  return (
    <Link to={link} className="group -m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
      {icon && <img className="flex-none w-16 h-16 rounded-lg object-cover" src={icon} />}
      <div className="ml-4">
        <p className="text-lg font-bold text-gray-700 flex justify-between">
          <span>{heading}</span>

          {trailingIcons && (
            <span>
              {trailingIcons.map(
                (trailingIcon, index) => <FontAwesomeIcon key={index} className="ml-2 text-gray-200 group-hover:text-gray-400" icon={trailingIcon} fixedWidth />
              )}
            </span>
          )}

        </p>
        {subheading && <p className="mt-1 text-base text-gray-500">
          {subheading}
        </p>}
      </div>
    </Link>
  )
}

export function DropdownFooter({ children }) {
  return (
    <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
      {children}
    </div>
  )
}

export function DropdownFooterItem({ icon, name }) {
  return (
    <div className="flow-root">
      <a href="#"
        className="-m-3 p-3 flex items-center rounded-md text-lg font-medium text-gray-600 hover:bg-gray-100">
        <FontAwesomeIcon className="text-gray-400" icon={icon} size="lg" />
        <span className="ml-3">{name}</span>
      </a>
    </div>
  )
}