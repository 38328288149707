import { faDiscord, faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import * as React from "react"
import { NewsletterSubsForm } from "./newsletter"

export function Footer() {
  return (
    <footer className="mx-auto footer text-base bg-gray-800 text-gray-300 relative pt-1 px-6">
      <div className="mx-auto max-w-5xl flex flex-col md:flex-row">

        <div className="mb-8 flex-1 grid grid-cols-3 gap-x-8">

          <FooterColumn>
            <FooterColumnHeader>Products</FooterColumnHeader>
            <FooterColumnItem><FooterLink to="/opticull">OptiCull</FooterLink></FooterColumnItem>
            <FooterColumnItem><FooterLink to="/cullai">CullAi (Legacy)</FooterLink></FooterColumnItem>
          </FooterColumn>

          {/* <FooterColumn>
            <FooterColumnHeader>Solutions</FooterColumnHeader>
            <FooterColumnItem>Photographers</FooterColumnItem>
          </FooterColumn> */}

          <FooterColumn>
            <FooterColumnHeader>Company</FooterColumnHeader>
            <FooterColumnItem><FooterLink to="/about">About</FooterLink></FooterColumnItem>
            <FooterColumnItem><FooterLink to="/blog">Blog</FooterLink></FooterColumnItem>
            <FooterColumnItem><FooterLink to="/help/cullai">Help</FooterLink></FooterColumnItem>
            <FooterColumnItem>
              <a className="text-gray-300 hover:text-gray-400"
                 href="https://drive.google.com/drive/folders/1T35rmDKhW9dfm1RSkDrK1Pww0H7kkwJE?usp=sharing" 
                 target="_blank">Media Kit</a>
            </FooterColumnItem>
          </FooterColumn>

          <FooterColumn>
            <FooterColumnHeader>Legal</FooterColumnHeader>
            <FooterColumnItem><FooterLink to="/privacy">Privacy</FooterLink></FooterColumnItem>
            <FooterColumnItem><FooterLink to="/terms">Terms</FooterLink></FooterColumnItem>
          </FooterColumn>

        </div>

        <NewsletterSubsForm></NewsletterSubsForm>

      </div>

      <div className="mx-auto max-w-7xl border-t-2 border-gray-700">
        <div className="flex flex-col md:flex-row items-center justify-between py-6">
          <div className="text-sm text-gray-400 font-medium pb-3 md:pb-0">
            Copyright © 2020-2024 BOOKMYSHOOT TECHNOLOGIES PRIVATE LIMITED. All rights reserved.
          </div>
          <div className="flex space-x-1 items-center">
            <a href="https://discord.gg/DQBFUub7ce" target="_blank"><FontAwesomeIcon className="text-gray-300 hover:text-gray-400" icon={faDiscord} size="lg" fixedWidth /></a>
            <a href="https://www.facebook.com/dopeaitech" target="_blank"><FontAwesomeIcon className="text-gray-300 hover:text-gray-400" icon={faFacebook} size="lg" fixedWidth /></a>
            <a href="https://www.instagram.com/dopeaitech" target="_blank"><FontAwesomeIcon className="text-gray-300 hover:text-gray-400" icon={faInstagram} size="lg" fixedWidth /></a>
            <a href="https://twitter.com/dopeaitech" target="_blank"><FontAwesomeIcon className="text-gray-300 hover:text-gray-400" icon={faTwitter} size="lg" fixedWidth /></a>
            <a href="https://www.linkedin.com/company/dopeaitech" target="_blank"><FontAwesomeIcon className="text-gray-300 hover:text-gray-400" icon={faLinkedin} size="lg" fixedWidth /></a>
            <div className="hidden md:block text-xs">To Follow Us</div>
          </div>
        </div>
      </div>
    </footer >
  )
}

export function FooterColumn({ children }) {
  return (
    <div className="mt-8 flex flex-col">
      {children}
    </div>
  )
}

export function FooterColumnHeader({ children }) {
  return (
    <span className="text-sm font-medium text-gray-400 uppercase mb-2">{children}</span>
  )
}

export function FooterColumnItem({ children }) {
  return (
    <span className="my-2">{children}</span>
  )
}

export function FooterLink({ children, to }) {
  return (
    <Link className="text-gray-300 hover:text-gray-400" to={to}>{children}</Link>
  )
}